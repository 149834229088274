import { Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { i18n } from 'common/locale';
import { useAuthentication } from './AuthenticationContext';
import LoginForm from './components/LoginForm';

const LoginContainer = styled('div')`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 500px;
`;

const Login = () => {
  const { isLoggedIn, login, microsoftLogin, msEntraLoginRedirect, isLoading, isMicrosoftLoggedIn } =
    useAuthentication();
  const { state } = useLocation();
  const [error, setError] = useState<string>();

  const prevUrl = ((state as any)?.redirectUrl as string) || '';
  let redirectTo = 'brancardage';
  if (prevUrl.includes('lits')) {
    redirectTo = '/lits';
  }
  if (isLoggedIn) {
    return <Navigate to={redirectTo} />;
  }

  const handleSubmit = async (username: string, password: string) => {
    try {
      await login(username, password);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <LoginContainer>
        <Stack gap={4} width="100%" alignItems="center">
          <LoginForm loading={isLoading} onSubmit={handleSubmit} error={error} />

          <Divider sx={{ width: '100%', marginTop: 3, marginBottom: 4 }} />
          {isMicrosoftLoggedIn && !isLoggedIn && !isLoading ? (
            <Typography color="error">{i18n.noMicrosoftAccountLinked}</Typography>
          ) : (
            <>
              <LoadingButton
                variant="outlined"
                fullWidth
                onClick={microsoftLogin}
                loading={isLoading}
                startIcon={<MicrosoftLogo height={16} width={16} style={{ marginRight: 4 }} />}
              >
                {i18n.microsoftLogin}
              </LoadingButton>
              <LoadingButton variant="outlined" fullWidth onClick={msEntraLoginRedirect} loading={isLoading}>
                {i18n.ssoLogin}
              </LoadingButton>
            </>
          )}
        </Stack>
      </LoginContainer>
    </Grid>
  );
};

const MicrosoftLogo = (props?: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" {...props}>
    <path fill="#f35325" d="M0 0h10v10H0z" />
    <path fill="#81bc06" d="M11 0h10v10H11z" />
    <path fill="#05a6f0" d="M0 11h10v10H0z" />
    <path fill="#ffba08" d="M11 11h10v10H11z" />
  </svg>
);

export default Login;
