import Api, { Cloud, User } from '@ambuliz/sabri-core';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ENVIRONMENT } from "configuration";
import { loginRequest } from 'core/authentication/msalConfig';
import { useCallback, useState } from 'react';

async function loginExternalUser({ id, accessToken, username }: Cloud.LinkExternalUserParams) {
  await Cloud.linkExternalUser({
    id,
    accessToken,
    username,
  });

  return Api.User.logInWith('microsoft', {
    authData: {
      id,
      access_token: accessToken,
    },
  });
}

export function useMicrosoftAuth() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [isLoading, setIsLoading] = useState(false);

  const login = useCallback(async () => {
    const otherActionInProgress = inProgress !== InteractionStatus.None || isLoading;
    if (otherActionInProgress) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await instance.loginPopup({ ...loginRequest, prompt: 'select_account' });

      const parseUser = await loginExternalUser({
        id: result.account.localAccountId,
        accessToken: result?.accessToken,
        username: result.account.username,
      });

      return parseUser;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [inProgress, instance, isLoading]);

  const logout = useCallback(
    async (username?: string) => {
      if (isAuthenticated) {
        const currentAccount = username ? instance.getAccountByUsername(username) : undefined;
        await instance.logoutRedirect({ account: currentAccount });
      }
    },
    [instance, isAuthenticated]
  );

  const entraLoginRedirect = useCallback(async () => {
    window.location.href = ENVIRONMENT.PARSE_SERVER_URL + '/auth/microsoft-entra-saml/login';
  }, []);

  const entraLogin = useCallback(async (authData: Parse.AuthData): Promise<User> => {
    try {
      setIsLoading(true);

      return await Api.User.logInWith<User>('microsoftEntraSaml', { authData });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const entraLogout = useCallback(async () => {
    const response = await Cloud.microsoftEntraLogout();

    if (response.url) {
      window.location.href = response.url;
    }
  }, []);

  return {
    login,
    entraLoginRedirect,
    entraLogin,
    logout,
    entraLogout,
    isAuthenticated,
  };
}
